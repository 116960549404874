export default function checkType(data) {
  let type = Object.prototype.toString.call(data)
  if (type === '[object String]') {
    type = 'String'
  } else if (type === '[object Number]') {
    type = 'Number'
  } else if (type === '[object Null]') {
    type = 'Null'
  } else if (type === '[object Boolean]') {
    type = 'Boolean'
  } else if (type === '[object Array]') {
    type = 'Array'
  } else if (type === '[object Object]') {
    type = 'Object'
  } else {
    type = '未进行判断的类型:' + type
  }
  return type
}
