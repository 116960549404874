import { createRouter, createWebHistory } from 'vue-router'
import _isMobile from '@/utils/isMobile'
// import { getDeviceType } from '@/utils'
// import setVisitorId from '@/utils/setVisitorId'
import routes from './routes'
// import { GetWxAuth } from '@/service/api/officialAccount'
import store from '@/store/index'
import { session } from '@/utils/storage'
import { aesDecryptHex, saveByCookie } from '@/service/api/pc'
// const isWechat = getDeviceType() === 'wechat'
import Cookies from 'js-cookie'
import { navLogin, getEventDetail } from '@/service/api/navLive'

// const wechatLogin = (query) => {
//   if (query?.openid) {
//     const { openid, unionId, language, sex, city, province, headimgurl, nickname } = query
//     store.commit('userStore/changeWechat', {
//       openid,
//       unionId,
//       language,
//       sex,
//       city,
//       province,
//       headimgurl,
//       nickname
//     })
//     console.log(store.state.userStore.wechatInfo)
//     const { href, search } = window.location
//     window.location.href = href.replace(search, session.get('searchParams'))
//   } else {
//     const { href, search } = window.location
//     session.set('searchParams', search)
//     let url = href
//     if (search) {
//       url = href.replace(search, '?h3c_get_wechat=1')
//     }
//     GetWxAuth({
//       url,
//       scope: 'snsapi_base'
//     }).then((res) => {
//       location.href = res.Data
//     })
//   }
// }

const getLiveStatus = async ({ id, source = '0' }) => {
  const eventid = Number(id)
  //eventdetail 4、会议信息明细
  const result = await getEventDetail({ eventid, source })
  // debugger
  // result.data.eventjoinstarttime = '2023-6-2 10:06'
  // result.data.eventstarttime = '2023-6-5 09:49'
  store.commit('commonStore/setEventInfo', result.data)
  return result.data.eventid
}
const clearCookie = () => {
  const domain = process.env.NODE_ENV === 'production' ? 'h3c.com' : 'localhost'
  Cookies.remove('h3c_user', { domain: domain })
}
const getUser = async () => {
  const domain = process.env.NODE_ENV === 'production' ? 'h3c.com' : 'localhost'
  let h3c_user = Cookies.get('h3c_user', { domain: domain })
  if (h3c_user) {
    // h3c_user = JSON.parse(h3c_user)
    //todo 解密
    const res = await aesDecryptHex({ json: h3c_user })
    // todo 解密后再存储数据到store
    let data = res ? JSON.parse(res.data) : null
    if (!data) {
      clearCookie()
      return false
    } else {
      if (!data.lang) {
        data.lang = 'cn'
        if (!data.phone) {
          clearCookie()
          return false
        }
      } else if (data.lang !== 'cn' && data.lang !== 'en') {
        clearCookie()
        return false
      } else if (data.lang === 'cn') {
        if (!data.phone) {
          clearCookie()
          return false
        }
      } else if (data.lang === 'en') {
        if (!data.email || !data.phone) {
          clearCookie()
          return false
        }
      }
    }
    if (data && data.portalUserId) {
      const cookieRes = await saveByCookie({ lang: data.lang, portalUserId: data.portalUserId })
      if (cookieRes?.data?.id) {
        store.commit('userStore/changeUser', cookieRes.data)
        store.commit('userStore/changeUserInfo', cookieRes.data)
        //todo 领航直播间token获取
        const params = {
          userid: cookieRes.data.phone,
          source: '0',
          openid: cookieRes.data.openid,
          elqsource: 'GWZC-官网注册-峰会',
          itemcode: 'MAS20Q2003-EVM01',
          menuid: ''
        }
        var identities = [
          {
            id: cookieRes.data.phone,
            type: 'mobilePhone'
          }
        ]
        window.linkflow.setIdentities(
          identities,
          function () {
            // success
            console.log('setIdentitiessuccess')
          },
          function () {
            // fail
            console.log('setIdentitiesfail')
          }
        )
        const login = await navLogin(params)
        if (login?.token) {
          store.commit('userStore/changeNavToken', login.token)
        }
      } else {
        const domain = process.env.NODE_ENV === 'production' ? 'h3c.com' : 'localhost'
        Cookies.remove('h3c_user', { domain: domain })
      }
    } else {
      const domain = process.env.NODE_ENV === 'production' ? 'h3c.com' : 'localhost'
      Cookies.remove('h3c_user', { domain: domain })
    }
  }
}
// 页面跳转置顶
const scrollT = () => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
}

const isRightClient = ({ name }) => {
  const prefixName = _isMobile() ? 'mb-' : 'pc-'
  if (name === 'index') return prefixName + 'index'
  if (name.startsWith(prefixName)) {
    return false
  } else {
    if (router.hasRoute(prefixName + name.substring(3))) {
      return prefixName + name.substring(3)
    } else {
      return prefixName + 'home'
    }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: ''
})
router.beforeEach(async (to, from, next) => {
  console.log('name', to.name, to.params, to.query, to, from)
  // https://www.h3ccom/cn/?utm_source=fwhmessage&utm_content=DM2302003-WBD08
  // 从 route.params 里面拿 utm_content 参数， utm_content有值就拿 utm_content的值
  // 没有给默认值 DM23Q2003-WBD01
  // wechatLogin(to.query)
  const nav_pc_mb_jump = session.get('nav_pc_mb_jump')
  if (to.query.is_form_cn_register) {
    session.set('is_form_cn_register', true)
    to.query.is_form_cn_register = ''
  } else if (to.query.pageId) {
    session.set('pageId', to.query.pageId)
    to.query.pageId = ''
  }
  if (nav_pc_mb_jump) {
    const nextName = isRightClient(nav_pc_mb_jump)
    session.remove('nav_pc_mb_jump')
    next({ name: nextName, params: nav_pc_mb_jump.params, query: nav_pc_mb_jump.query })
  } else {
    // if (!openid && isWechat) {
    //   wechatLogin(to.query)
    // }
    const nextName = isRightClient(to)
    if (nextName) {
      session.set('nav_pc_mb_jump', to)
      window.location.href = process.env.VUE_APP_BASE_H5_URL
    } else {
      const domain = process.env.NODE_ENV === 'production' ? 'h3c.com' : 'localhost'
      let h3c_user = Cookies.get('h3c_user', { domain: domain })
      if (h3c_user) {
        await getUser()
      }
      if (to.name === 'pc-session') {
        if (store.state.commonStore.eventInfo.id) {
          next()
        } else {
          const status = await getLiveStatus(to.params, to.query)
          if (status) {
            next()
          } else {
            // message({ content: '无直播场次', key: '无直播场次' })
            next({ name: 'pc-home', query: to.query })
          }
        }
      } else {
        next()
      }
    }
  }
  scrollT()
})

export default router
