export default function (app) {
  app.directive('click-outside', {
    mounted(el, binding) {
      // 点击指定元素以外的任意区域都会触发 clickoutside 事件
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          // vnode.context[binding.expression](event)
          binding.value && binding.value()
          console.log(typeof binding.value)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })
}
