// import checkType from '@/utils/checkType'

export default {
  namespaced: true,
  state() {
    return {
      menuKey: ['index'],
      navInfo: [],
      unReadCount: 0,
      eventInfo: {},
      chatTop: {}
    }
  },
  getters: {
    getHeaderMenuKey(state) {
      return state.menuKey
    },
    getUnReadCount(state) {
      return state.unReadCount
    },
    getEventInfo(state) {
      return state.eventInfo
    },
    getChatTop(state) {
      return state.chatTop
    },
    getBannerList(state) {
      let key = ''
      if (state.menuKey[0] === 'index') {
        key = 'index'
      } else {
        key = 'forumDetail?typeId=' + state.menuKey[0]
      }
      let arr = state.navInfo.filter((i) => i.barUrl === key)[0]
      let arrImg = arr?.barImg.split(',') || []
      let arrUrl = arr?.barImgUrl?.split(',') || []
      arr = arrImg.map((i, index) => {
        return {
          src: i,
          url: arrUrl[index]
        }
      })
      return arr
    }
  },
  mutations: {
    changeHeaderMenuKey(state, payload) {
      state.menuKey[0] = payload
    },
    setNavInfo(state, payload) {
      state.navInfo = payload
    },
    setUnReadCount(state, payload) {
      state.unReadCount = payload
    },
    setEventInfo(state, payload) {
      state.eventInfo = payload
    }
  },
  actions: {}
}
