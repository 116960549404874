import HTTPRequest from '..'
// import store from '@/store'
const jsonBase = new HTTPRequest({
  baseURL: process.env.VUE_APP_BASE_JSON_API_URL,
  timeout: process.env.VUE_APP_API_TIMEOUT,
  interceptors: {
    requestInterceptor: (config) => {
      // console.log('config', config)
      // let token = store.state.userStore.navToken
      // config.headers.authtoken = token || ''
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export default jsonBase
