import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import commonStore from './modules/commonStore'
import userStore from './modules/userStore'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    commonStore,
    userStore
  },
  plugins: [
    // createPersistedState({
    //   // 存储方式：localStorage、sessionStorage、cookies
    //   storage: window.sessionStorage,
    //   // 存储的 key 的key值
    //   key: 'yyh-store'
    // })
  ]
})
