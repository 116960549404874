<template>
  <a-config-provider :locale="locale" :getPopupContainer="popupContainer">
    <metainfo></metainfo>
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  setup() {
    const locale = zhCN
    return {
      locale,
      popupContainer: (node) => {
        if (node && node.getAttribute('class') === 'ant-select-selector') {
          return node.parentNode
        }
        return document.body
      }
    }
  }
}
</script>

<style lang="less">
@import url('@/assets/css/index/variable.less');
@import url('@/assets/css/index/index.less');
//@import url('@/assets/font/font.css');
#app {
  height: 100%;
}
body {
  font-family: 'Microsoft YaHei', Arial, sans-serif !important;
}
</style>
