'use strict'

import navBase from '@/service/request/navBase'
import store from '@/store'
export function navLogin(data) {
  let api = 'userlogin'
  return navBase.post({
    url: '',
    data,
    params: { api }
  })
}
export function getEventDetail(data) {
  let api = 'eventdetail'
  return navBase.post({
    url: '',
    data,
    params: { api }
  })
}
export function subscribe(data) {
  let api = 'usereventsubscribe'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}

export function eventquest(data) {
  let api = 'eventquest'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
export function liveDownLoad(data) {
  let api = 'userdownload'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
export function saveQuestioner(data) {
  let api = 'eventquestsubmit'
  return navBase.post({
    url: '',
    data,
    params: { api }
  })
}
export function onlineVisitorList(data) {
  let api = 'onlineVisitorList'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
export function getLogLid(data) {
  let api = 'adddadianlog'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
export function addPlayBakLog(data) {
  let api = 'updtimelog'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
export function addPlayBakClickLog(data) {
  let api = 'addddclicklog'
  return navBase.post({
    url: '',
    data,
    params: { api },
    headers: { authtoken: store.state.userStore.navToken }
  })
}
