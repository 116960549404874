// import checkType from '@/utils/checkType'
import Cookies from 'js-cookie'
export default {
  namespaced: true,
  state() {
    let wechat = Cookies.get('H3C_WECHAT')
    return {
      userInfo: {},
      user: {},
      wechatInfo: wechat ? JSON.parse(wechat) : {},
      navToken: '',
      enroll: '',
      logoCodeTwo: '',
      logoCode: '',
      muduUser: {}
    }
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo
    },
    getUser(state) {
      return state.user
    },
    getMuduUser(state) {
      return state.muduUser
    },
    getWechat(state) {
      return state.wechatInfo
    },
    getNavToken(state) {
      return state.navToken
    },
    getLogoCode(state) {
      return state.logoCode
    },
    getLogoCodeTwo(state) {
      return state.logoCodeTwo
    },
    getEnroll(state) {
      return state.enroll
    },
    //是否通过鉴权2
    passAuthentication2(state) {
      const auth2Keys = ['newIndustryId', 'company', 'job', 'h3cRelation']
      let flag = false
      //是否登录
      if (!state.user.id) return '1'
      // 是否通过鉴权2
      auth2Keys.forEach((item) => {
        if (!state.user[item]) {
          flag = '2'
        }
      })
      return flag
    }
  },
  mutations: {
    changeUserInfo(state, payload) {
      state.userInfo = JSON.parse(JSON.stringify(payload))
    },
    changeUser(state, payload) {
      state.user = JSON.parse(JSON.stringify(payload))
    },
    changeMuduUser(state, payload) {
      state.muduUser = JSON.parse(JSON.stringify(payload))
    },
    changeWechat(state, payload) {
      Cookies.set('H3C_WECHAT', JSON.stringify(payload), { expires: 30 })
      state.wechatInfo = JSON.parse(JSON.stringify(payload))
    },
    changeNavToken(state, payload) {
      state.navToken = payload
      // console.log(payload)
    },
    changeLogoCode(state, payload) {
      state.logoCode = payload
    },
    changeLogoCodeTwo(state, payload) {
      state.logoCodeTwo = payload
    },
    changeEnroll(state, payload) {
      state.enroll = payload
    }
  },
  actions: {}
}
