const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/pc'
  },
  // {
  //   path: '/mb',
  //   name: 'mb-index',
  //   redirect: '/mb/home',
  //   // component: () => import('@/views/mobile/index.vue'),
  //   children: [
  //     {
  //       path: '/mb/home',
  //       name: 'mb-home',
  //       component: () => import('@/views/mobile/home/index.vue'),
  //       meta: { metaInfo: { title: '这是标题' } }
  //     }
  //   ]
  // },
  {
    path: '/pc',
    name: 'pc-index',
    redirect: '/home',
    component: () => import('@/views/pc/index.vue'),
    children: [
      {
        path: '/home',
        name: 'pc-home',
        component: () => import('@/views/pc/home/index.vue'),
        meta: { page_name: '首页' }
      },
      {
        path: '/agenda',
        name: 'pc-agenda',
        component: () => import('@/views/pc/agenda/index.vue')
      },
      {
        path: '/speaker',
        name: 'pc-speaker',
        component: () => import('@/views/pc/speaker/index.vue')
      },
      {
        path: '/partner',
        name: 'pc-partner',
        component: () => import('@/views/pc/partner/index.vue')
      },
      {
        path: '/download',
        name: 'pc-download',
        component: () => import('@/views/pc/download/index.vue')
      },
      {
        path: '/personal_center',
        name: 'pc-user',
        component: () => import('@/views/pc/user/index.vue'),
        meta: { page_name: '个人中心' }
      },
      {
        path: '/rules',
        name: 'pc-rule',
        component: () => import('@/views/pc/rule/index.vue'),
        meta: { page_name: '规则页' }
      },
      {
        path: '/news',
        name: 'pc-news',
        component: () => import('@/views/pc/news/index.vue')
      },
      {
        path: '/session/:id',
        name: 'pc-session',
        component: () => import('@/views/pc/live/index.vue')
      },
      {
        path: '/review',
        name: 'pc-review',
        component: () => import('@/views/pc/review/index.vue')
      }
    ]
  },
  {
    path: '/register',
    name: 'pc-register',
    // redirect: '/enroll',
    component: () => import('@/views/enroll/index.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

export default routes
