import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import '@/global/css/global.css'
import 'video.js/dist/video-js.min.css'
import 'videojs-markers/dist/videojs.markers.min.css'
// import '@/assets/font/font.css'
import checkType from '@/utils/checkType'
import registerElement from './global/js/register-element'
import { Base64 } from 'js-base64'
import btnClickDebounce from '@/global/js/directives/btnClickDebounce'
import clickOutside from '@/global/js/directives/clickOutside'
import onScreenDireactive from '@/global/js/directives/onScreenDireactive'
import mitt from 'mitt'
import '@/global/js/flexible'
function addDpiListener(callback) {
  let mql = window.matchMedia('screen and (resolution: ' + window.devicePixelRatio + 'dppx)')
  mql.addListener(function () {
    console.log(mql)
    if (mql.matches) {
      callback(window.devicePixelRatio)
    }
  })
}
addDpiListener(function (dpi) {
  console.log(`DPI changed to ${dpi}`)
})
import { createMetaManager } from 'vue-meta'
const metaManager = createMetaManager(false, {
  meta: { tag: 'meta', nameless: true }
})
window.checkType = checkType
window.window.myBase64 = Base64.encode
const plugins = {
  install: (app) => {
    app.config.globalProperties.$bus = mitt()
  }
}

// const clientWidth = document.documentElement.clientWidth
// const fontSize = clientWidth / 100
// document.documentElement.style.fontSize = `${fontSize}px`
const app = createApp(App)
const init = async () => {
  app.use(registerElement)
  app.use(btnClickDebounce)
  app.use(clickOutside)
  app.use(onScreenDireactive)
  app.use(plugins)
  app.use(store).use(router).use(metaManager)
  await router.isReady()
  app.mount('#app')
  document.dispatchEvent(new Event('render-event'))
}

init()
