export default function (app) {
  app.directive('on-screen', {
    mounted(el, binding) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries

          if (entry.isIntersecting) {
            binding.value(true)
          } else {
            binding.value(false)
          }
        },
        { threshold: 0 }
      )

      observer.observe(el)

      // Cleanup the observer
      el._intersectionObserver = observer
    },
    unmounted(el) {
      if (!el._intersectionObserver) {
        return
      }
      el._intersectionObserver.disconnect()
      delete el._intersectionObserver
    }
  })
}
