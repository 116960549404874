import { createVNode } from 'vue'
import HTTPRequest from '..'
import { sessionCache } from '@/utils/cache'
import { message, Modal } from 'ant-design-vue'
import router from '@/router/index'
import store from '@/store/index'

const myRequest = new HTTPRequest({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: process.env.VUE_APP_API_TIMEOUT,
  interceptors: {
    requestInterceptor: (config) => {
      // let token = sessionCache.getCache('yyh-store')?.userStore?.userInfo?.token.token
      // 拦截权限等级为登录
      if (config.permission?.level === 'login' && !store.state.userStore.userInfo) {
        message.warning(`需登录后才可${config.permission?.msg}`)
        sessionCache.setCache('routerRecord', {
          name: router.currentRoute.value.name,
          query: router.currentRoute.value.query,
          meta: router.currentRoute.value.meta
        })
        router.push({
          name: 'login'
        })
        return
      }
      // 拦截权限等级为实名
      if (config.permission?.level === 'certification') {
        if (!store.state.userStore.userInfo) {
          sessionCache.setCache('routerRecord', {
            name: router.currentRoute.value.name,
            query: router.currentRoute.value.query,
            meta: router.currentRoute.value.meta
          })
          router.push({
            name: 'login'
          })
          message.warning(`需登录且实名后才可${config.permission?.msg}`)
          return
        } else if (store.state.userStore.userInfo.isCertification == '0') {
          Modal.confirm({
            title: '实名认证',
            content: createVNode(
              'div',
              {
                style: 'color:red;'
              },
              `需登录且实名后才可${config.permission?.msg}，检测到您的账号未实名，点击确认可前往实名。`
            ),
            onOk() {
              router.push({
                name: 'certification'
              })
            },
            onCancel() {}
          })
          return
        }
      }

      config.params = {
        ...config.params,
        language: 'cn'
      }
      // config.headers.token = token || 'null'
      // config.headers.tenant = '0000'
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      if (res.data.isError) {
        let m = null
        if (!m) {
          if (res.data.code == '40001' || res.data.code == '40005') {
            store.commit('userStore/changeUserInfo', null)
            store.commit('userStore/changeUser', {})
            router.push({
              name: 'login'
            })
          } else {
            m = message.error({
              content: res.data.msg,
              key: 'errorMsg',
              duration: 2
            })
          }
        }
      }
      return res
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})

export default myRequest
