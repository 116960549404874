import {
  ConfigProvider as AConfigProvider,
  Button as AButton,
  Layout as ALayout,
  Menu as AMenu,
  Row as ARow,
  Col as ACol,
  Dropdown as ADropdown,
  Badge as ABadge,
  Popover as APopover,
  Input as AInput,
  Avatar as AAvatar,
  Card as ACard,
  Tabs as ATabs,
  Pagination as APagination,
  Drawer as ADrawer,
  Empty as AEmpty,
  Form as AForm,
  Select as ASelect,
  Checkbox as ACheckbox,
  Radio as ARadio,
  BackTop as ABackTop,
  TreeSelect as ATreeSelect,
  Tag as ATag,
  List as AList,
  Affix as AAffix,
  Table as ATable,
  Upload as AUpload,
  Divider as ADivider,
  Comment as AComment,
  Image as AImage,
  Modal as AModal,
  Popconfirm as APopconfirm,
  Space as ASpace,
  Tooltip as ATooltip,
  Progress as AProgress
} from 'ant-design-vue'
import {
  EditOutlined,
  BellOutlined,
  MobileOutlined,
  UserOutlined,
  SearchOutlined,
  EyeOutlined,
  MessageOutlined,
  LikeOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  MailOutlined,
  LockOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  CrownOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ShareAltOutlined,
  StarOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  WeiboOutlined,
  WechatOutlined,
  QrcodeOutlined,
  EditTwoTone,
  TagOutlined,
  CloseOutlined,
  FileTextOutlined,
  GiftOutlined,
  PlayCircleOutlined,
  SwapOutlined
} from '@ant-design/icons-vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
const components = [
  AConfigProvider,
  AButton,
  ALayout,
  ARow,
  ACol,
  AMenu,
  ADropdown,
  ABadge,
  APopover,
  AInput,
  AAvatar,
  ACard,
  ATabs,
  APagination,
  ADrawer,
  AForm,
  ASelect,
  ACheckbox,
  ARadio,
  ABackTop,
  ATreeSelect,
  ATag,
  AList,
  AAffix,
  ATable,
  ADivider,
  AComment,
  AModal,
  AEmpty,
  AUpload,
  AImage,
  ASpace,
  APopconfirm,
  ATooltip,
  AProgress
]
const icons = [
  EditOutlined,
  BellOutlined,
  MobileOutlined,
  SearchOutlined,
  UserOutlined,
  EyeOutlined,
  MessageOutlined,
  LikeOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  DownOutlined,
  DownloadOutlined,
  UpOutlined,
  MailOutlined,
  LockOutlined,
  ArrowUpOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  CrownOutlined,
  FormOutlined,
  StarOutlined,
  LoadingOutlined,
  PlusOutlined,
  ShareAltOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DeleteTwoTone,
  WeiboOutlined,
  WechatOutlined,
  QrcodeOutlined,
  EditTwoTone,
  TagOutlined,
  CloseOutlined,
  FileTextOutlined,
  GiftOutlined,
  PlayCircleOutlined,
  SwapOutlined
]
const swiper = [Swiper, SwiperSlide]
export default function (app) {
  for (const component of components) {
    app.use(component)
  }
  for (const icon of icons) {
    app.component(icon.displayName, icon)
  }
  for (const i of swiper) {
    app.component(i.name, i)
  }
}
