// 'use strict'
// import http from '@/utils/http'
// validEmail
// import { validMobile } from '@/utils/validation'
import myRequest from '../request/indexBase'
import jsonBase from '@/service/request/jsonBase'

// 根据手机号发送验证码
export function getCode(data) {
  return myRequest.post({
    url: '/h3c/sso/getPhoneCode',
    data
  })
}

// 鉴权一注册接口
export function uthOne(data) {
  return myRequest.post({
    url: '/h3c/authUser/authOne',
    data
  })
}
// 中文登录
export function loginChina(data) {
  return myRequest.post({
    url: '/h3c/authUser/loginChina',
    data
  })
}
// 鉴权一获取图形验证码
export function getCaptchaApi(params) {
  return myRequest
    .get({
      url: '/h3c/validateCode/captcha',
      params,
      responseType: 'arraybuffer'
    })
    .then((data) => `data:image/png;base64,${btoa(String.fromCharCode(...new Uint8Array(data)))}`)
}

// 解密
export function aesDecryptHex(data) {
  return myRequest.post({
    url: '/h3c/aes/aesDecryptHex',
    data
  })
}
// 加密
export function aesEncrypt(data) {
  return myRequest.post({
    url: '/h3c/aes/aesEncrypt',
    data
  })
}
// 单独预约接口
export function schedule(data) {
  return myRequest.post({
    url: '/h3c/schedule-c',
    data
  })
}

// 查询预约接口
export function getMakeStatus(params) {
  return myRequest.get({
    url: '/h3c/schedule-c/getMakeStatus',
    params
  })
}
// 根据cookie保存用户数据
export function saveByCookie(data) {
  return myRequest.post({ url: '/h3c/authUser/addUserByCookie', data })
}

// 查询个人信息接口
export function desensitization(data) {
  return myRequest.post({
    url: '/h3c/authUser/desensitization',
    data
  })
}

// 查询用户是否预约日程
export function getMyScheduleDate(params) {
  return myRequest.get({
    url: '/h3c/schedule-c/getMyScheduleDate',
    params
  })
}

// 根据用户Id查询预约,不分页
export function getMyScheduleList(params) {
  return myRequest.get({
    url: '/h3c/schedule-c/getMyScheduleList',
    params
  })
}

// 查询任务等级
export function getAllLevel(params) {
  return myRequest.get({
    url: '/h3c/level-c/getAllLevel',
    params
  })
}
// 查询任务列表
export function getUserTaskC(params) {
  return myRequest.get({
    url: '/h3c/task-c/getUserTaskC',
    params
  })
}

export function getSchedule() {
  return jsonBase.get({
    url: 'scheduleChinese.json'
  })
}

// 查询用户已预约日程
export function getMakeScheduleList(params) {
  return myRequest.get({
    url: '/h3c/schedule-c/getMakeScheduleList',
    params
  })
}
// 查询抽奖次数
export function getUserDrawNum(params) {
  return myRequest.get({
    url: '/h3c/drawNum/getUserDrawNum',
    params
  })
}
// 查询个人信息接口
export function saveTaskLogC(data) {
  return myRequest.post({
    url: '/h3c/task-c/saveTaskLogC',
    data
  })
}
// 查询嘉宾json文件
export function guestChinese() {
  return jsonBase.get({
    url: 'guestChinese.json'
  })
}

// 查询嘉宾json文件
export function partner() {
  return jsonBase.get({
    url: 'partner.json'
  })
}

// 查询首页伙伴json文件
export function partnerHome() {
  return jsonBase.get({
    url: 'partnerHome.json'
  })
}
// 查询用户中奖结果
export function findPrizeResult(params) {
  return myRequest.get({
    url: '/h3c/userDrawPrizeRecord/findPrizeResult',
    params
  })
}
// 用户填写中奖信息
export function saveUserAddress(data) {
  return myRequest.post({
    url: '/h3c/userDrawResult/saveUserAddress',
    data
  })
}
// 查询用户已经填写的信息
export function userDrawResult(data) {
  return myRequest.post({
    url: '/h3c/userDrawResult/userAddressIsFill',
    data
  })
}
// 查询资料总数
export function getUserResourceNum(params) {
  return myRequest.get({
    url: '/h3c/resource/getUserResourceNum',
    params
  })
}
// 查询资料列表
export function getUserResourceList(params) {
  return myRequest.get({
    url: '/h3c/resource/getUserResourceList',
    params
  })
}

// 新闻资讯列表json文件
export function newsList() {
  return jsonBase.get({
    url: 'newsChinese.json'
  })
}
//鉴权二-中文补全用户信息

export function authTwoUserInformation(data) {
  return myRequest.post({
    url: '/h3c/authUser/authTwoUserInformation',
    data
  })
}
//鉴权二-判断邮箱是否已注册

export function authTwoEmail(params) {
  return myRequest.post({
    url: '/h3c/authUser/email',
    params
  })
}

export function getCompanyList(params) {
  return myRequest.get({
    url: '/h3c/company/getCompanyList',
    params
  })
}
// 问卷联调
export function saveQuestionAnswers(data) {
  return myRequest.post({
    url: '/h3c/question-record/saveQuestionAnswers',
    data
  })
}

export function getTrendSignature(params) {
  return myRequest.get({
    url: '/h3c/validateCode/getTrendSignature',
    params
  })
}
export function liveDownloadRecord(data) {
  return myRequest.post({
    url: '/h3c/live-download-record/liveDownloadRecord',
    data
  })
}
export function liveView(data) {
  return myRequest.post({
    url: '/h3c/userLiveLogs',
    data
  })
}
// 查询嘉宾金句json文件
export function guestGolden() {
  return jsonBase.get({
    url: 'guestGolden.json'
  })
}

// 查询精彩回顾
export function getReview() {
  return jsonBase.get({
    url: 'review.json'
  })
}
